#mp__panel.confirmation-account-panel
.text-bold {
  font-weight: bold;
}

@media (max-width: 768px) {
  #mp__panel.confirmation-account-panel
  .position-content {
    width: 100%;
  }
}

@media (max-width: 1024px) {
  #mp__panel.confirmation-account-panel
  .position-content {
    width: 100%;
    padding-bottom: 160px;
  }
}


#mp__confirmation-account #mp__button {
  margin-top: 24px;
}

#mp__confirmation-account #mp__panel .image-wrapper {
  top: 5px;
  left: -15%;
}

#mp__confirmation-account #mp__panel .image-wrapper img {
  max-width: 85%;
}

@media (min-width: 1300px) {
  #mp__confirmation-account #mp__panel .position-content {
    padding: 7% 35% 17.5% 35%;
  }
}

@media (max-width: 1300px) {
  #mp__confirmation-account #mp__panel .panel-wrapper {
    width: 58%;
  }
}

@media (max-width: 1300px) {
  #mp__confirmation-account #mp__panel .panel-wrapper {
    width: 58%;
  }
}

@media (max-width: 1024px) {
  #mp__confirmation-account #mp__panel .panel-wrapper {
    width: 70%;
  }

  #mp__confirmation-account #mp__panel .confirmation-account-panel
  #mp__confirmation-account #mp__panel .position-content {
    text-align: center;
  }
}

@media (max-width: 768px) {
  #mp__confirmation-account #mp__panel .panel-wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  #mp__confirmation-account #mp__panel .confirmation-account-panel
  #mp__confirmation-account #mp__panel .position-content {
    text-align: center;
  }
}

@media (min-width: 1440px) {
  #mp__confirmation-account #mp__panel .confirmation-account-panel {
    margin-bottom: 202px;
  }
}
