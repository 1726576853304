* {
    font-family: 'Poppins', sans-serif;
    font-style: normal;
}

.welcome {
    width: 15.188rem;
}

.welcome-title-text {
    font-style: normal;
    font-weight: 600;
    font-size: 28px;
    line-height: 48px;
    color: #202121;
}

.welcome-body {
    width: 100%;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: #202121;
}

.button-portal {
    width: 15rem;
}