:root {
  --custom-primary-dark: #5D4EE8;
  --custom-neutral-light: #dbdcdc;
}

.contract {
  height: 552px;
  overflow-y: scroll;
  scrollbar-color: var(--custom-primary-dark) var(--custom-neutral-light);
  scrollbar-width: thin;
}

.contract::-webkit-scrollbar {
  width: 6px;
  height: 6px
}

.contract::-webkit-scrollbar-track {
  background: var(--custom-neutral-light);
  border-radius: 10px;
}

.contract::-webkit-scrollbar-thumb {
  background-color: var(--custom-primary-dark);
  border-radius: 10px;
}

@media (max-width: 640px) {
  .wrapper-document {
    width: 100%;
  }
}