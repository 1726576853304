@tailwind components;

@layer components {
    #mp__hunting-password.wrapper {
        @apply container m-auto grid justify-center items-center py-14 px-5 sm:px-10 md:px-20 text-xs md:text-base;

        line-height: 1.25rem;
    }
    #mp__hunting-password .mp__hunting_password-title {
        @apply text-2xl font-semibold md:text-center;
    }
    #mp__hunting-password .mp__hunting_password-p {
        @apply md:px-20;

        max-width: 50rem;
        text-align: justify;
        word-wrap: break-word;
    }
    #mp__hunting-password .mp__hunting_password-form {
        @apply md:px-20 pt-5;
    }
    #mp__hunting-password .mp__hunting_password-action {
        @apply md:px-20 mt-12 mb-24 md:mb-36;
    }
    #mp__hunting-password .mp__hunting_password-action_button {
        @apply rounded-lg w-full;
    }
}