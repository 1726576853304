:root {
  --custom-primary-dark: #5D4EE8;
}

.tooltip {
  width: 15rem;
  background: #E2DEFF;
  min-height: 4rem;
}

.tooltip.show {
  opacity: 1;
}

.tooltip .tooltip-arrow {
  display: none;
}

.tooltip .tooltip-inner {
  background: transparent;
  padding: 18px;
  max-width: 100%;
  text-align: left;
}

.tooltip,
.tooltip__text,
.tooltip-Info-link {
  font-size: 12px;
  color: #5D4EE8;
}

a {
  font-weight: bold;
}

.tooltip__content {
  width: 1.125rem;
  height: 1.125rem;
}

.tooltip__icon {
  cursor: pointer;
  width: 1.3rem;
  height: 1.3rem;
  display: block;
}

.tooltip__wrapper
.tooltip__title {
  font-size: 12px;
  color: var(--custom-primary-dark);
  cursor: pointer;
}