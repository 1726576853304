/* new code */
#mp__panel.confirmation-email-panel
.panel-content {
  width: 65%;
}

#mp__panel.confirmation-email-panel
.text-bold {
  font-weight: bold;
}

@media (max-width: 768px) {
  #mp__panel.confirmation-email-panel
  .position-content {
    width: 100%;
  }

  #mp__panel.confirmation-email-panel
  .panel-content {
    width: 100%;
  }

  #mp__panel.confirmation-email-panel
  .panel-description {
    margin-bottom: 48px;
  }

  #mp__panel.confirmation-email-panel
  .image-mobile-wrapper {
    display: flex;
    justify-content: center;
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  #mp__panel.confirmation-email-panel
  .panel-wrapper {
    width: 70%;
  }

  #mp__panel.confirmation-email-panel
  .position-content {
    width: 100%;
    text-align: center;
  }
}

@media (min-width: 1024px) and (max-width: 1439px){
  #mp__panel.confirmation-email-panel
  .panel-wrapper {
    width: 58%;
  }
}

@media (min-width: 1440px) {
  #mp__panel.confirmation-email-panel
  .panel-description {
    width: 88%;
  }

  #mp__panel.confirmation-email-panel {
    margin-bottom: 202px;
  }

  #mp__panel.confirmation-email-panel
  .position-content {
    width: 85%;
  }
}