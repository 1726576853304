#mp__password.password-container {
  font-size: 14px;
}

.group {
  width: initial;
  margin: initial;
  padding: initial;
}

#mp__password .icon-visible-password {
  transform: translateY(calc(-50% + 3px));
  right: 16px;
  color: #929497;
}

#mp__password .icon-visible-password-invalid {
  right: 30px;
  bottom: 24px;
}

.mp__password-needs {
  color: #6D6E70
}

#mp__password input.is-invalid ~ .icon-visible-password {
    display: none;
}