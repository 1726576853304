.custom-alert {
  border: 1px solid;
  padding: 16px;
  padding-right: 48px;
  width: 378px;
  display: inline-block;
  font-size: 14px;
  text-align: left;
  margin-bottom: 0;
}
.toast-body {
  padding: 0;
  padding-bottom: 5px;
}
.toast-body,
.toast {
  display: inline-block;
}
.toast {
  width: initial;
  font-size: initial;
  background-color: transparent;
  box-shadow: initial;
  border-radius: initial;
  background-color: initial;
  border: initial;
}

.notification-container {
  position: fixed;
  top: 20px;
  right: 20px;
  width: 385px;
}

@media (max-width: 576px) {
  .notification-container {
    display: block;
    left: 2.5%;
    width: 95%;
  }
  .custom-alert {
    width: 100%;
  }
  .toast {
    width: 100%;
  }
  .toast-body {
    display: block;
  }
}
.close::before,
.close::after {
  cursor: pointer;
  content: '';
  width: 1px;
  position: absolute;
  height: 16px;
  top: 15px;
  right: 24px;
}

.close::after {
  transform: rotate(45deg);
}

.close::before {
  transform: rotate(-45deg);
}

.success {
  background-color: #f5fdd2;
  border-color: #a2dd21;
  color: #3d6a06;
}

.success .close::before,
.success .close::after {
  background-color: #3d6a06;
}

.error {
  background-color: #ffe8d5;
  border-color: #ff502d;
  color: #ff502d;
}

.error .close::before,
.error .close::after {
  background-color: #ff502d;
}

.warning {
  background-color: #fffad9;
  border-color: #ffdc42;
  color: #7a5d0c;
}

.warning .close::before,
.warning .close::after {
  background-color: #7a5d0c;
}
