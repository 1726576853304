:root {
  --custom-default-neutral: #202121;
  --custom-default-primary: #5D4EE8;
  --custom-default-primary-darkest: #17117A;
  --custm-default-neutral-lightest: #FCFDFC;
}

#mp__product-integration .progress-step-wrapper {
  margin-bottom: 51px;
}

#mp__product-integration .mp__integration-progress_mobile {
  display: none;
}

#mp__product-integration .product-integration-wrapper {
  max-width: 29.5rem;
  padding-bottom: 19.5rem;

  display: grid;
  margin: auto;
}

#mp__product-integration .product-integration-title {
  color: var(--custom-default-neutral);
  font-weight: 600;
  font-size: 14px;

  margin-right: 12px;
}

#mp__product-integration .product-integration-info {
  display: flex;
  align-items: center;
  justify-content: center;

  margin-bottom: 51px;
}

#mp__product-integration .select-integrator-wrapper {
  margin-bottom: 48px;
}

#mp__product-integration .button-content {
  display: flex;
  justify-content: space-between;
}

#mp__product-integration #mp__button .no-integrator {
  width: 227px;
  background-color: transparent;
  border-color: var(--custom-default-primary);
  color: var(--custom-default-primary);
  border-radius: 8px;
}

#mp__product-integration #mp__button .no-integrator:hover {
  background-color: var(--custom-default-primary);
  border-color: var(--custom-default-primary);
  color: var(--custm-default-neutral-lightest);
  border-radius: 8px;
}

#mp__product-integration #mp__button .enable-button {
  width: 100%;
  background-color: var(--custom-default-primary);
  border-color: var(--custom-default-primary);
  color: var(--custm-default-neutral-lightest);
  border-radius: 8px;
}

#mp__product-integration #mp__button .enable-button:hover {
  background-color: var(--custom-default-primary-darkest);
  border-color: var(--custom-default-primary-darkest);
}

@media (max-width: 768px) {
  #mp__product-integration .progress-step-wrapper {
    display: none;
  }
  #mp__product-integration .mp__integration-progress_mobile {
    display: block;
    margin-bottom: 30px;
  }
  #mp__product-integration .button-content {
    flex-direction: column-reverse;
    align-items: center;
  }

  #mp__product-integration #mp__button .enable-button {
    width: 100%;
    margin-bottom: 24px;
  }

  #mp__product-integration #mp__button {
    width: 100%;
  }

  #mp__product-integration #mp__button .no-integrator {
    width: 100%;
  }

  #mp__product-integration .product-integration-wrapper {
    padding: 0 20px 120px;
  }
}

.mp__integration__notify-container {
  position: fixed;
  top: 3.688rem;
  right: 5.5rem;
  width: 23.625rem;
}

.mp__integration__notify-container button {
  background: transparent
    url('data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23000%27%3e%3cpath d=%27M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z%27/%3e%3c/svg%3e')
    center/1em auto no-repeat;
}

@media (max-width: 913px) {
  .mp__integration__notify-container {
    position: fixed;
    top: 4.688rem;
    width: 100%;
    right: 0;
    padding: 24px 20px;
  }
}
