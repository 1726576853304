:root {
  --custom-primary-dark: #5D4EE8;
  --custom-error-lightest: #FFE8D5;
  --custom-error-medium: #FF502D;
  --custom-neutral-dark: #6D6E70;
  --custom-neutral-lightest: #f9f9f9;
  --custom-neutral-light: #dbdcdc;
  --custom-default-neutral: #202121;
}

.group {
  width: 73.5%;
  margin-bottom: 30px;
}

.label {
  display: flex;
  justify-content: space-between;

  font-size: 12px;
  color: var(--custom-neutral-dark);
  margin: 0 16px 10px;
}

.input {
  width: 100%;
  font-size: 14px;
  border-radius: 8px;
  background-color: var(--custom-neutral-lightest);
  border: 1px solid var(--custom-neutral-light);
  padding: 0.79rem 1rem;
}

.input:focus {
  border: 1px solid var(--custom-primary-dark);
  box-shadow: 0 4px 4px rgba(33, 33, 33, 0.12);
  caret-color: var(--custom-default-neutral);
}

.validated{
  font-size: 12px;
  color: var(--custom-error-medium);
  padding-left: 16px;
}

.was-validated
.form-control:invalid {
  background-color: var(--custom-error-lightest);
  border: 1px solid var(--custom-error-medium);
  background-image: url("../../assets/svg/validateError.svg");
}

.form-control.is-invalid {
  background-color: var(--custom-error-lightest);
  background-image: url("../../assets/svg/validateError.svg");
}

.was-validated
.form-control:invalid:focus {
  box-shadow: none;
  caret-color: var(--custom-error-medium);
}

.was-validated
.form-control:valid {
  border: 1px solid var(--custom-neutral-light);
  box-shadow: none;
  background-image: none;
}

.was-validated
.form-control:valid:focus {
  border: 1px solid var(--custom-primary-dark); 
  box-shadow: none;
}

@media (max-width: 768px) {
  .group {
    width: 100%;
    padding: 0 20px;
  }

  .input {
    width: 100%;
  }
}

@media (max-width: 256px) {
  .label {
    margin: 0 7px 10px;
  }
}

.forgot-password {
  font-family: 'Poppins';
  font-size: 12px;
  font-weight: 400;
  color: var(--custom-primary-dark);
}
