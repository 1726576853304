#mp__panel.panel-update-email.panel-container {
  width: 80%;
}

#mp__panel.panel-update-email .panel-wrapper {
  width: 100%;
}

#mp__panel.panel-update-email .description {
  font-size: 14px;
}

@media (min-width: 769px) {
  #mp__panel.panel-update-email.panel-container {
    margin: 120px 5% 200px auto;
  }
  #mp__panel.panel-update-email.panel-container {
    transform: translateX(60px);
    margin: 120px auto 200px;
    max-width: 710px;
  }
}
@media (max-width: 769px) {
  #mp__panel.panel-update-email.panel-container {
    width: 90%;
    margin: 48px auto 150px;
  }
}
#mp__panel.panel-update-email .image-wrapper {
  left: -120px;
}
.custom-ordered-list {
  margin-top: 24px;
}

.custom-ordered-list .text-bold {
  font-weight: bold;
}

.button-update-panel {
  position: absolute;
  bottom: 0;
  transform: translateY(calc(100% + 48px));
  width: 100%;
}
@media (min-width: 769px) {
  .button-update-panel {
    left: 50%;
    transform: translate(-50%, calc(100% + 48px));
    max-width: 470px;
  }
}
.update-panel-link {
  display: block;
  text-align: center;
  margin-bottom: 120px;
  color: #5D4EE8;
  font-size: 14px;
}
@media (min-width: 769px) {
  .update-panel-link {
    display: none;
  }
}