@tailwind components;

@layer components {
    .mp__integration-container {
        @apply container m-auto grid justify-center items-center pt-14 pb-60 px-5 md:px-20 max-w-2xl;
    }
    .mp__integration-title {
        @apply text-sm font-semibold text-center pb-12;
    }
    .mp__integration-text {
        @apply text-justify md:pr-10 pt-6;

        word-wrap: break-word;
    }
    .mp__integration-progress_desktop {
        @apply block;
    }
    @media (max-width: 913px) {
        .mp__integration-progress_desktop {
            @apply hidden;
        }
    }
    .mp__integration-progress_mobile {
        @apply block px-5 py-6;
    }
    @media (min-width: 914px) {
        .mp__integration-progress_mobile {
            @apply hidden;
        }
    }
    .mp__integration-button {
        @apply w-full rounded-lg my-6;
    }
}

.mp__integration-container .wrapper-input-copy .group {
    display: inline-block;
    width: 50%;
}
.mp__integration-container #mp__input.wrapper-input-copy .input-copy {
    display: inline-block;
    width: 100%;
}
.mp__integration-container #mp__input #mp__button .button-copy {
    position: inherit;
    top: 0;
    line-height: initial;
    height: initial;
    width: 100%;
}
.mp__integration-container .wrapper-input-copy .group
> div {
    width: 100%;
}

.mp__integration-container .wrapper-input-copy #mp__button {
    display: inline-block;
    width: 50%;
    position: relative;
}

.mp__integration-container .wrapper-input-copy #mp__button .button-copy {
    height: initial;
    width: initial;
}
