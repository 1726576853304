
@media (min-width: 768px) {
  #mp__panel.panel-resend-email .position-content {
    padding: 64px 120px 56px;
  }
}

#mp__panel.panel-resend-email .description {
  font-size: 14px;
}

#mp__panel.panel-resend-email .container-btns {
  display: flex;
  gap: 16px
}

#mp__panel.panel-resend-email .group {
  margin: 48px 0;
}

#mp__panel.panel-resend-email .panel-wrapper {
  width: 80%;
  max-width: 770px;
}

#mp__panel.panel-resend-email .container-btns .button {
  width: 100%;
}

@media (min-width: 769px) {
  #mp__panel.panel-resend-email .container-btns div {
    flex-basis: 50%;
  }

  #mp__panel.panel-resend-email .panel-wrapper {
    background-color: rgb(219 220 220 / 20%);
  }
}

@media (max-width: 768px) {
  #mp__panel.panel-resend-email .position-content {
    padding-top: 24px;
    padding-bottom: 24px;
  }

  #mp__panel.panel-resend-email .group {
    margin-top: 24px;
  }

  #mp__panel.panel-resend-email .container-btns {
    flex-direction: column;
    gap: 34px;
  }

  #mp__panel.panel-resend-email .button:last-of-type {
    order: -1;
  }

  #mp__panel.panel-resend-email .button.outline-blue {
    border-color: transparent;
    background-color: transparent;
  }

  #mp__panel.panel-resend-email .button.outline-blue:hover {
    color: initial;
  }

  .mp__resend-email-notify-container {
    top: 4.688rem;
    width: 100%;
    right: 0;
    padding: 24px 20px;
  }
}

.mp__resend-email-notify-container {
  position: fixed;
  top: 3.688rem;
  right: 5.5rem;
  width: 23.625rem;
}

.mp__resend-email-notify-container
.btn-close {
  background: transparent
    url('data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23000%27%3e%3cpath d=%27M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z%27/%3e%3c/svg%3e')
    center/1em auto no-repeat;
  filter: none;
}
