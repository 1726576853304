@tailwind components;

@layer components {
  #mp__forgot-password .mp__forgot_password-container {
    @apply m-auto grid lg:flex lg:flex-col justify-center items-center md:px-20 px-5 py-12;
  }
  #mp__forgot-password .confirmation__mobile {
    @apply grid md:hidden;
  }
  #mp__forgot-password .confirmation__desktop {
    @apply hidden md:block;
  }
  #mp__forgot-password .confirmation__desktop-panel {
    @apply mb-36;
  }
  #mp__forgot-password .confirmation__desktop-panel .image-wrapper {
    @apply w-72 top-6;

    left: -17%;
  }
  #mp__forgot-password .confirmation__desktop-panel .panel-wrapper {
    width: 712px;
    height: 372px;
  }
  #mp__forgot-password .confirmation__desktop-panel .position-content {
    @apply w-full pr-10;
  }
  #mp__forgot-password .confirmation__desktop-panel .position-content button {
    @apply mt-7;
  }
  @media (max-width: 768px) {
    #mp__forgot-password .mp__forgot_password-container {
      @apply relative;
    }
  }
  @media (min-width: 768px) {
    #mp__forgot-password .mp__forgot_password-container {
      padding-left: 25%;
      padding-right: 25%;
    }
  }
  @media (min-width: 1024px) {
    #mp__forgot-password .mp__forgot_password-container {
      padding-left: 32%;
      padding-right: 32%;
    }
  }
  @media (min-width: 1500px) {
    #mp__forgot-password .mp__forgot_password-container {
      padding-left: 37%;
      padding-right: 37%;
    }
  }
  #mp__forgot-password .mp__forgot_password-container.confirmation {
    @apply block md:grid pt-6;
  }
  #mp__forgot-password .mp__forgot_password-image {
    @apply flex justify-center items-center mb-7;
  }
  #mp__forgot-password .mp__forgot_password-title {
    @apply text-left lg:text-center font-semibold mb-2;

    font-size: 1.4rem;
    line-height: 2rem;
  }
  #mp__forgot-password .mp__forgot_password-title.password {
    font-size: 1.3rem;
  }
  #mp__forgot-password .mp__forgot_password-text {
    @apply text-left lg:text-center mb-12 sm:text-sm;
  }
  @media (max-width: 640px) {
    #mp__forgot-password .mp__forgot_password-text {
      font-size: 0.77rem;
      line-height: 1.25rem;
    }
  }
  #mp__forgot-password .sent .mp__forgot_password-text {
    @apply mb-72;
  }
  #mp__forgot-password .confirmation__mobile .mp__forgot_password-text {
    @apply mt-6;
  }
  #mp__forgot-password .mp__forgot_password-input {
    @apply mb-6 lg:w-full;
  }
  @media (min-width: 768px) {
    #mp__forgot-password .mp__forgot_password-input {
      max-width: 470px;
    }
  }
  #mp__forgot-password .confirmation__mobile .mp__forgot_password-action {
    @apply mb-12;
  }
  #mp__forgot-password .mp__forgot_password-action {
    @apply grid lg:flex lg:flex-row-reverse lg:flex-row lg:w-full mt-6 mb-28 gap-3;
  }
  @media (min-width: 768px) {
    #mp__forgot-password .mp__forgot_password-action {
      max-width: 470px;
    }
  }
  @media (max-width: 300px) {
    #mp__forgot-password .mp__forgot_password-action {
      margin-bottom: 3rem;
    }

    #mp__forgot-password .sent .mp__forgot_password-text {
      margin-bottom: 1rem;
    }

    #mp__forgot-password .mp__forgot_password-title {
      padding-right: unset;
    }
  }
  #mp__forgot-password #mp__button {
    @apply w-full;
  }
  #mp__forgot-password #mp__button button.mp__forgot_password-action_button {
    @apply rounded-lg w-full;
  }
  #mp__forgot-password .mp__forgot_password-action_button.cancel {
    @apply text-primaryDark border-neutralLightest hover:bg-neutralLightest hover:text-primaryDark
        hover:border-neutralLightest focus:bg-neutralLightest focus:text-primaryDark focus:border-neutralLightest
        lg:text-primaryDark border-primaryDark lg:hover:bg-primaryDark lg:hover:text-neutralLightest
        lg:hover:border-primaryDark lg:focus:bg-primaryDarkest lg:focus:text-neutralLightest focus:border-primaryDarkest;
  }
  @media (max-width: 640px) {
    #mp__forgot-password .mp__forgot_password-notification {
      @apply left-0 right-0 top-1;
    }
  }
  #mp__forgot-password .mp__forgot_password-notification {
    @apply absolute sm:top-3;

    right: 8%;
  }
  #mp__forgot-password .mp__forgot_password-toast {
    @apply relative hidden sm:block;
  }
  #mp__forgot-password .mp__forgot_password-toast_mobile {
    @apply block sm:hidden;
  }
}

#mp__recovar-password .notify-container {
  position: fixed;
  top: 3.688rem;
  right: 5.5rem;
  width: 23.625rem;
}

#mp__recovar-password .notify-container .btn-close {
  background-image: url('../../assets/svg/closeNotification.svg');
  opacity: 1;
  filter: invert(48%) sepia(79%) saturate(2476%) hue-rotate(41deg) brightness(118%) contrast(119%);
}
