:root {
  --custom-success-lightest: #F5FDD2;
  --custom-success-medium: #A2DD21;
  --custom-success-darkest: #3D6A06;
  --custom-secondary-dark: #00A5A5;
  --custom-neutral-lightest: #FCFDFC;
}

#mp__input.wrapper-input-copy {
  position: relative;
}

#mp__input .btn-close {
  background-image: url('../../assets/svg/closeNotification.svg');
  opacity: 1;
  filter: invert(48%) sepia(79%) saturate(2476%) hue-rotate(41deg) brightness(118%) contrast(119%);
}

#mp__input .btn-close:focus {
  box-shadow: unset;
}

#mp__input .input-copy {
  width: 55%;
  padding-right: 36px;
}

#mp__input .input-copy, #mp__input .form-control:disabled, #mp__input .form-control[readonly] {
  background-color: transparent;
}

#mp__input .input-successfully-copied {
 background-color: var(--custom-success-lightest);
 color: var(--custom-success-darkest);
 border-color: var(--custom-success-medium);
}

#mp__input .notification-container {
  position: fixed;
  top: 7.688rem;
  right: 1.5rem;
  width: 23.625rem;
}

@media (max-width: 576px) {
  #mp__input .notification-container {
    display: block;
    left: 2.5%;
    width: 95%;
  }
}
#mp__input #mp__button .button-copy {
  position: absolute;
  right: 0;
  top: 27.4px;
  width: 48.5%;
  height: 48.25px;
  background: var(--custom-secondary-dark);
  color: var(--custom-neutral-lightest);
  line-height: 19px;
  font-weight: 400;
  border-radius: 8px;
}

@media (max-width: 320px) {
  #mp__input #mp__button .button-copy {
    font-size: 12px;
  }
}

#mp__input .button-copy:focus {
  background-color: var(--custom-secondary-dark);;
}

#mp__input .button-copy:hover {
  background-color: var(--custom-secondary-dark);
  border-color: var(--custom-secondary-dark);
}
