.mp__card-envvias-container > * {
    max-width: 226px;
    max-height: 284px;
    border-radius: 7rem 0.5rem 0.5rem 0.5rem;
    background: var(--custom-success-medium);
    border: solid var(--custom-primary-darkest) 0.1rem;
}

.mp__card-envvias-container .mp__card-envvias-body {
    font-size: 14px;
}
