#mp__panel.mp__pas-panel
.position-content {
  padding: 4rem 4.838rem 3.775rem 14.625rem;
}
#mp__panel.mp__pas-panel 
.panel-title {
  width: 100%;
  max-width: 222px;
}

#mp__pas-error-screen_button {
  margin-top: 3rem;
}

@media (max-width: 768px) {
  #mp__panel.mp__pas-panel
  .position-content {
    padding: 0;
    text-align: center;
  }

  #mp__panel.mp__pas-panel 
  .panel-title {
    width: 95%;
  }

  #mp__panel.mp__pas-panel.panel-container {
    margin: 3rem 1.25rem 8.531rem;
  }

  #mp__pas-error-screen_button {
    max-width: 100% !important;
  }
}

@media (min-width: 769) and (max-width: 1200px) {
  #mp__panel.mp__pas-panel 
  .panel-wrapper,
  .image-wrapper {
    left: -18%;
  }
}