@media (min-width: 600px) and (max-width: 992px) {
    .mp__envvias-cards {
        /*padding: 0 5rem;*/
    }
}

#mp__button, .mp__envvias-button {
    width: 100%;
    margin: auto;
}

@media (min-width: 1024px) {
    .mp__envvias-button {
        width: 30rem;
    }
}