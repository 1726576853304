.know-more-link {
    color: #5D4EE8;
  }
  
  #mp__panel.mp__pas-non-approved-integrators_panel .panel-title {
    max-width: 65%;
  }
  
  #mp__pas-non-approved-integrators_button {
    margin-top: 1.75rem;
  }
  
  #mp__panel.mp__pas-non-approved-integrators_panel .image-wrapper {
    position: absolute;
    top: 24px;
    left: -17%;
  }
  
  .mp__pas-non-approved-integrators_desc {
    max-width: 88%;
  }
  
  #mp__panel.mp__pas-non-approved-integrators_panel .position-content {
    padding: 4rem 4.838rem 6.68rem 14.625rem;
  }
  
  @media (max-width: 768px) {
    #mp__panel.mp__pas-non-approved-integrators_panel .panel-title {
      max-width: 100%;
    }
  
    #mp__panel.mp__pas-non-approved-integrators_panel .position-content {
      text-align: left;
    }
  
    #mp__panel.mp__pas-non-approved-integrators_panel.panel-container {
      margin: 1.438rem 0.938rem 7.5rem 0.938rem;
    }
    #mp__panel.mp__pas-non-approved-integrators_panel .image-mobile-wrapper img{
      margin: 0 auto;
    }
    #mp__panel.mp__pas-non-approved-integrators_panel .panel-button {
      max-width: 100%;
    }
  }
  
  @media (min-width: 769px) and (max-width: 1024px) {
    #mp__panel.mp__pas-non-approved-integrators_panel .image-wrapper {
      position: absolute;
      top: 24px;
      left: -6%;
    }
  }