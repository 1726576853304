@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --custom-default-neutral: #202121;
  --custom-default-neutral-light: #f9f9f9;
}

.mp__data-bank-progressStep_desktop {
  margin-bottom: 3rem;
}

.mp__bank-data-title {
  color: var(--custom-default-neutral);
  font-weight: 600;
  font-size: 14px;
}
.mp__bank-data__notify-container {
  position: fixed;
  top: 3.688rem;
  right: 5.5rem;
  width: 23.625rem;
}

.mp__bank-data__notify-container button {
  background: transparent
    url('data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23000%27%3e%3cpath d=%27M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z%27/%3e%3c/svg%3e')
    center/1em auto no-repeat;
}

.mp__bank-data-subtitle {
  color: var(--custom-default-neutral);
  font-weight: 400;
  font-size: 14px;
}

.mp__bank-data-content {
  max-width: 29.5rem;
  padding-bottom: 5.688rem;
  margin: auto;
}

.mp__agency_input.is-invalid ~ .invalid-feedback {
  padding-left: 0;
}

.titles-content {
  text-align: center;

  margin-bottom: 3rem;
}

.mp__data-bank-select_wrapper #mp__custom-select .mp__custom-select__button {
  background-color: var(--custom-default-neutral-light);
}

.mp__bank-data-title_wrapper {
  margin-bottom: 0.625rem;
}

.mp__bank-data_wrapper {
  position: relative;
  display: flex;
  justify-content: space-between;
}

.mp__agency_input {
  width: 347px;
}

.mp__account-digit_input.is-invalid ~ .invalid-feedback, 
.mp__agency-digit_input {
  width: 106px;
}

.mp__account_input.is-invalid ~ .invalid-feedback {
  padding-left: 0;
}

.mp__bank-code_input.is-invalid ~ .invalid-feedback {
  padding-left: 0;
}

.mp__bank-name_input.is-invalid ~ .invalid-feedback {
  padding-left: 0;
}

.mp__agency-digit_tooltip {
  position: absolute;
  top: 43px;
  right: 7px;
}

.mp__account-digit_input {
  width: 106px;
}

.mp__account-digit_input.is-invalid ~ .mp__account-digit_tooltip {
  display: none;
}

.mp__account-wrapper {
  position: relative;
  display: flex;
  justify-content: space-between;

  margin-bottom: 3rem;
}

.mp__account_input {
  width: 347px;
}

.mp__account-digit_tooltip {
  position: absolute;
  top: 43px;
  right: 7px;
}

.mp__account_digit_input.is-invalid ~ .mp__account-digit_tooltip {
  display: none;
}

.mp__bank-data_buttons {
  display: flex;
  justify-content: space-between;
}

.mp__bank-not-found-button {
  width: 227px;
}

.mp__bank-next-button {
  width: 100%;
}

.mp__data-bank-select_wrapper {
  margin-bottom: 1.5rem;
}

@media (max-width: 913px) {
  .mp__data-bank-progressStep_desktop {
    display: none;
  }

  .mp__bank-data__notify-container {
    position: fixed;
    top: 4.688rem;
    width: 100%;
    right: 0;
    padding: 24px 20px;
  }
}

@media (max-width: 500px) {
  .mp__bank-data-content {
    display: block;
    padding: 0 1.25rem 6.438rem;
  }

  .mp__agency_input {
    width: 100%;
    margin-right: 16px;
  }

  .mp__account_input {
    width: 100%;
  }

  .mp__bank-data_buttons {
    flex-direction: column-reverse;
  }

  .mp__bank-data_buttons .button {
    width: 100%;
  }

  #mp__bank-data-next_button {
    margin-bottom: 1.5rem;
  }

  .titles-content {
    text-align: left;
  }

  .mp__agency_input_wrapper {
    width: 100%;
    margin-right: 16px;
  }

  .mp__account_input_wrapper {
    width: 100%;
    margin-right: 16px;
  }
  
  .mp__account-digit_input_wrapper {
    margin-left: 0px !important;
  }

  .mp__account-digit_input_wrapper {
    width: 36%;
  }
}

.mp__account-digit_input_wrapper {
  margin-left: 20px;
}

.mp__account-digit_tooltip .is-invalid {
  display: none;
}

.mp__data-bank-select_wrapper .mp__select-info {
  margin-bottom: 10px;
}

#mp__bank-select2 .product-integration-wrapper .dropdownIndicator img {
  margin: 0.875rem;
}

#mp__bank-select2 span {
  display: none;
}

.mp__select2-infos label {
  font-weight: 400;
  font-size: 12px;
  color: #6D6E70;
}

.mp__select2-infos button {
  color: #5d4ee8;
}

.mp__no-bankdata-text {
  display: flex;
  flex-direction: column;
  gap: 1.75rem;
  margin-bottom: 1.5rem;
}

.mp__no-bankdata-text button {
  color: #5d4ee8;
  font-weight: bold;
}

.mp__no-bankdata-text p {
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.5rem;
}

.mp__bank-data_wrapper {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
@media (max-width: 350px) {
  .mp__bank-data_wrapper {
    flex-direction: column;
  }
}

.mp__bank-code_wrapper input {
  width: 8.75rem;
}

.mp__bank-name_wrapper input {
  width: 19.625rem;
}

.mp__suggestion-bank_wrapper {
  display: flex;
  gap: 1rem;
  margin-bottom: 3rem;
}

@media (max-width: 900px) {
  .mp__suggestion-bank_wrapper {
    flex-direction: column;
  }

  .mp__bank-code_wrapper input {
    width: 100%;
  }

  .mp__bank-name_wrapper input {
    width: 100%;
  }
}

.mp__agency-wrapper {
  position: relative;
  display: flex;
  justify-content: space-between;

  margin-bottom: 1.5rem;
}

.mp__account-digit_input.is-invalid ~ .invalid-feedback {
  padding-left: 0;
}