:root {
  --custom-primary-dark: #5D4EE8;
  --custom-neutral-light: #dbdcdc;
}

.mp__default-contract-section {
  overflow-y: scroll;
  height: 34.5rem;
  padding: 3rem 2rem;
  background: rgb(219 220 220 / 20%);
  border-radius: 0.5rem;

  scrollbar-color: var(--custom-primary-dark) var(--custom-neutral-light);
  scrollbar-width: thin;

  margin-bottom: 3.188rem;
}

.mp__default-contract-section::-webkit-scrollbar {
  width: 6px;
  height: 6px
}

.mp__default-contract-section::-webkit-scrollbar-track {
  background: var(--custom-neutral-light);
  border-radius: 10px;
}

.mp__default-contract-section::-webkit-scrollbar-thumb {
  background-color: var(--custom-primary-dark);
  border-radius: 10px;
}

.mp__default-contract-section p {
  font-size: 14px;
  margin-bottom: 20px;
  text-align: left;
  word-wrap: break-word;
}

.mp__document-title {
  text-align: center;
}

@media (max-width: 768px) {
  .mp__default-contract-section  {
    padding: 0.625rem 0.938rem;
  }

  .mp__document-title {
    text-align: left;
  }
}
