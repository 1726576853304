.container-progress-bar {
  padding: 16px;
  background-color: rgb(226 222 255 / 20%);
  font-size: 12px;
  border-radius: 8px 8px 0 0;
}

.container-progress-bar ~ .progress {
  height: 6px;
  background-color: #a79cff;
  border-radius: 0 0 8px 8px;
}

.container-progress-bar ~ .progress .progress-bar {
  background-color: #5d4ee8;
}

.progress-bar-subtitle {
  color: #5d4ee8;
  margin-left: 10px;
}
