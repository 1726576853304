:root {
  --custom-default-neutral: #202121;
}

.not-found-container {
  margin: auto;
  margin-top: 7.563rem;
  display: grid;
  align-items: center;
  justify-content: center;
  grid-template-columns: 18.5% 48%;
}

.text-content {
  display: grid;
  row-gap: 24px;
  margin: 0 0 7.438rem 0;
}

.title-wrapper
.title {
  font-size: 28px;
  line-height: 48px;
  font-weight: 600;

  color: var(--custom-default-neutral);
}

.subtitle-wrapper {
  width: 85%;
  margin-bottom: 24px;
}

.subtitle-wrapper
.subtitle {
  font-size: 14px;
  line-height: 24px;
  font-weight: 400;

  color: var(--custom-default-neutral);
}

.image-content {
  display: grid;
  justify-content: center;
  margin-top: 0.625rem;
}

.mp__back-previous-btn {
  padding: 12px 9px;
}

.back-previous-btn-wrapper
#mp__button
.mp__back-previous-btn {
  padding: 12px 9px;
}

.back-initial-btn-wrapper
#mp__button
.mp__back-initial-btn {
  padding: 12px 16.5px;
}

@media (max-width: 1023px) {
  .not-found-container {
    margin: 3rem 1.25rem 0;
    grid-template-columns: 100%;
  }

  .text-content {
    text-align: center;
    margin: 0 0 4.125rem 0;
    justify-items: center;
  }

  .subtitle-wrapper {
    width: 100%;
    padding: 0 2rem 1.5rem;
    margin-bottom: 0;
  }

  .back-initial-btn-wrapper,
  .back-previous-btn-wrapper {
    width: 100%;
  }

  #mp__not-found_back-initial-btn,
  #mp__not-found_back-previous-btn {
    width: 100%;
  }
}

@media (min-width: 1024px) and (max-width: 1439px) {
  .not-found-container {
    grid-template-columns: 25.5% 67%;
  }
}