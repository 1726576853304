:root {
    --custom-default-neutral-dark: #6D6E70;
    --custom-default-primary-dark: #5D4EE8;
  }
  
  .maintenance-page-container {
    margin: auto;
    margin-top: 3rem;
    display: grid;
    align-items: center;
    justify-content: center;
    grid-template-columns: 20% 37%;
  }

  .maintenance-page-container
  .maintenance-text-content {
    display: grid;
    row-gap: 24px;
    margin-bottom: 2.625rem;
  }
  
  .title-wrapper
  .title {
    font-size: 28px;
    line-height: 48px;
    font-weight: 600;
  
    color: var(--custom-default-primary-dark);
  }
  
  .maintenance-text-wrapper,
  .about-via-text-wrapper {
    width: 85%;
  }
  
  .maintenance-text-wrapper
  .maintenance-text {
    font-size: 14px;
    line-height: 24px;
    font-weight: 400;
    margin-bottom: 22px;
  
    color: var(--custom-default-neutral-dark);
  }

  .about-via-text-wrapper
  .about-via-text {
    font-size: 14px;
    line-height: 24px;
    font-weight: 400;
  
    color: var(--custom-default-neutral-dark);
  }
  
  .image-mobile-content {
    display: none;
  }

  .image-content {
    display: grid;
    justify-content: center;
  }
  
  .mktplace-link-wrapper
  span {
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    cursor: pointer;

    color: var(--custom-default-primary-dark);
  }
  
  @media (max-width: 1023px) {
    .maintenance-page-container {
      margin: 3rem 1.25rem 0;
      grid-template-columns: 100%;
    }
  
    .maintenance-page-container
    .maintenance-text-content {
      justify-items: flex-start;
      margin-bottom: 0.625rem;
    }
  
    .maintenance-text-wrapper
    .about-via-text-wrapper {
      width: 100%;
      padding: 0 2rem 1.5rem;
      margin-bottom: 0;
    }

    .image-mobile-content {
      display: grid;
      justify-content: center;
      margin-top: 0.625rem;
    }

    .image-content {
      display: none;
    }
  }
  
  @media (min-width: 1024px) and (max-width: 1439px) {
    .maintenance-page-container {
      grid-template-columns: 24.5% 55%;
    }
  }
