#mp__email-sent #mp__panel .image-wrapper img {
  max-width: 85%;
}
#mp__email-sent #mp__panel .image-wrapper {
  top: 5px;
  left: -15%;
}
#mp__email-sent #mp__panel .panel-title {
  width: 100%;
  max-width: 212px;
}
#mp__email-sent #mp__panel .panel-description {
  font-size: 12px;
}
