#mp__panel.invalid-cnpj-panel .description {
  color: #202121;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  margin-bottom: 24px;
  font-family: 'Poppins';
}

#mp__panel.invalid-cnpj-panel .bold {
  font-weight: bold;
}
#mp__panel.invalid-cnpj-panel
.panel-content {
  width: 65%;
}

@media (max-width: 768px) {
  #mp__panel.invalid-cnpj-panel
  .position-content {
    width: 100%;
  }

  #mp__panel.invalid-cnpj-panel
  .panel-content {
    width: 100%;
  }

  #mp__panel.invalid-cnpj-panel
  .panel-description {
    margin-bottom: 48px;
  }

  #mp__panel.invalid-cnpj-panel
  .image-mobile-wrapper {
    display: flex;
    justify-content: center;
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  #mp__panel.invalid-cnpj-panel
  .panel-wrapper {
    width: 70%;
  }

  #mp__panel.invalid-cnpj-panel
  .position-content {
    width: 100%;
  }
}

@media (min-width: 1024px) and (max-width: 1439px){
  #mp__panel.invalid-cnpj-panel
  .panel-wrapper {
    width: 58%;
  }
}

@media (min-width: 1440px) {
  #mp__panel.invalid-cnpj-panel
  .panel-description {
    width: 88%;
  }

  #mp__panel.invalid-cnpj-panel {
    margin-bottom: 202px;
  }
}

@media  (min-width: 769px) {
  #mp__panel.invalid-cnpj-panel .button.mobile {
    display: none;
  }
}
@media  (max-width: 768px) {
  #mp__panel.invalid-cnpj-panel .description.bold {
    margin-bottom: 48px;
  }
  #mp__panel.invalid-cnpj-panel .panel-container {
    margin-top: 48px
  }
}