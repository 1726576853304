:root {
  --custom-primary-dark: #5D4EE8;
  --custom-primary-darkest: #17117A;
  --custom-error-lightest: #FFE8D5;
  --custom-error-medium: #FF502D;
  --custom-neutral-dark: #6D6E70;
  --custom-neutral-lightest: #f9f9f9;
  --custom-neutral-light: #dbdcdc;
  --custom-neutral-white: #fcfdfc;
  --custom-default-neutral: #202121;
}

* {
  font-family: 'Poppins';
}

#mp__login.login-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

#mp__login .login-content {
  background-color: rgb(219 220 220 / 20%);
  padding: 60px 121px 48px;
  width: 100%;
  height: 100vh;
  max-height: 480px;
  max-width: 712px;
  border-radius: 8px;
  margin-top: 48px;
  margin-bottom: 120px;
  gap: 1rem;
  display: grid;
}

@media (max-width: 256px) {
  #mp__login .login-content {
    margin-top: 0;
    margin-bottom: 75px;
  }
}

#mp__login .login-button {
  width: 100%;
  border-radius: 8px;
  background-color: var(--custom-primary-dark);
  border: var(--custom-primary-dark);
  color: var(--custom-neutral-white);
  font-size: 14px;
  font-weight: 600;
}

#mp__login .login-button:focus,
#mp__login .login-button:hover {
  background: var(--custom-primary-darkest);
  border: var(--custom-primary-darkest);
}

#mp__login .not-yet-registered {
  text-align: center;
  margin-top: 24px;
  color: var(--custom-default-neutral);
  font-size: 14px;
}

#mp__login .not-yet-registered a {
  color: var(--custom-primary-dark);
}



@media (max-width: 768px) {
  #mp__login .login-content{
    padding: 120px 20px 0;
    background-color: unset;
  }
}

#mp__login .remember-user {
   margin: 20px 0 17px;
}

@media (max-width: 768px) {
  #mp__login .notify-container {
    top: 4.688rem;
    width: 100%;
    right: 0;
    padding: 24px 20px;
  }
}

#mp__login .notify-container {
  position: fixed;
  top: 3.688rem;
  right: 5.5rem;
  width: 23.625rem;
}

#mp__login .notify-container
.btn-close {
  background-image: url('../../assets/svg/closeNotification.svg');
  opacity: 1;
  filter: invert(48%) sepia(79%) saturate(2476%) hue-rotate(41deg) brightness(118%) contrast(119%);
}