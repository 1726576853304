* {
    font-family: 'Poppins', sans-serif;
    font-style: normal;
}

.card-container {
    display: flex;
    align-items: center;
    justify-content: center;
}

.content {
    width: 348px;
    height: 448px;
    overflow: hidden;
    background: rgb(219 220 220 / 30%);
    border-radius: 8px;
    transition: 0.5s;
    animation: ease-in;
}

.content:hover {
    transform: scale(1.03);
    border-radius: 8px;
}

.body {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    height: 4.313rem;
    font-size: 14px;
    line-height: 24px;
    font-weight: 400;
    padding: 0.5rem 4rem 0.5rem 2rem;
}

.card-title {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #202121;
    padding: 0 2rem;
}