.mp_skeleton {
  opacity: .7;
  animation: skeleton-loading 1s linear infinite alternate;
}

.mp_skeleton-text {
  width: 100%;
  height: .5rem;
  margin-bottom: .25rem;
  border-radius: .125rem;
}

.mp_skeleton-text:last-child {
  margin-bottom: 0;
  width: 80%;
}

@keyframes skeleton-loading {
  0% {
    background-color: hsl(200, 20%, 70%);
  }

  100% {
    background-color: hsl(200, 20%, 95%);
  }
}

.mp_skeleton-header {
  width: 100%;
  height: 5rem;

  margin-bottom: 4rem;
}

#mp__skeleton-register .mp__skeleton-header {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;

  margin-bottom: 4rem;
}

#mp__skeleton-register .mp__skeleton-header_title {
  width: 8.313rem;
  height: 2rem;
  margin-bottom: 1.5rem;
}

#mp__skeleton-register .mp__register-header_text {
  width: 48%;
  height: 1rem;
  margin-bottom: .25rem;
  border-radius: .125rem;
}

#mp__skeleton-register .mp__register-form {
  display: grid;
  justify-content: center;
  align-items: center;
}
#mp__skeleton-register .mp__register-form_content {
  position: relative;
  padding-top: .75rem;
  padding-bottom: .75rem;
}

.mp__skeleton-label-1 {
  width: 2.063rem;
  height: .875rem;
  margin-left: 1rem;
}

.mp__skeleton-label-2 {
  width: 7.438rem;
  height: .875rem;
  margin-left: 1rem;
}

.mp__skeleton-label-3 {
  width: 2.688rem;
  height: .875rem;
  margin-left: 1rem;
}

.mp__skeleton-label-4 {
  width: 3.25rem;
  height: .875rem;
  margin-left: 1rem;
}

.mp__skeleton-label-5{
  width: 2rem;
  height: .875rem;
  margin-left: 1rem;
}

.mp__skeleton-label-6 {
  width: 2.375rem;
  height: .875rem;
  margin-left: 1rem;
}

#mp__skeleton-register .mp__skeleton-input {
  width: 29.375rem;
  height: 3rem;
  border-radius: .5rem;
}

#mp__skeleton-register .mp__skeleton-input-medium {
  width: 14.188rem;
  height: 3rem;
  border-radius: .5rem;
}

#mp__skeleton-register .skeleton-progress-strength {
  width: 23.75rem;
  height: .25rem;
}

#mp__skeleton-register .skeleton-strength-password {
  width: 4.625rem;
  height: .875rem;
}

.skeleton-password-needs {
  width: 8.375rem;
  height: .5rem;
}

.skeleton-role-password-needs-1 {
  width: 8.438rem;
  height: .5em;
  margin-left: .188rem;
}

.skeleton-role-password-needs-2 {
  width: 12rem;
  height: .5em;
  margin-left: .188rem;
}

.skeleton-role-password-needs-3 {
  width: 26.375rem;
  height: .5em;
  margin-left: .188rem;
}

.skeleton-role-password-needs-4 {
  width: 9rem;
  height: .5em;
  margin-left: .188rem;
}

.skeleton-circle {
  border-radius: 50%;
  width: .75rem;
  height: .75rem;
}

.skeleton-checkBox-wrapper {
  margin-top: 3.188rem;
}

.skeleton-checkBox {
  width: 1.125rem;
  height: 1.125rem;
  border-radius: .125rem;
}

.skeleton-checkBox-text {
  margin-left: .438rem;
  width: 19.313rem;
  height: .5rem;
}

.mp__skeleton-button {
  width: 29.375rem;
  height: 3rem;
  border-radius: .5rem
}

.mp_skeleton-footer {
  width: 100%;
  height: 15.875rem;
  margin-top: 11.125rem;
}

#mp__skeleton-register .mp__register-form_checkbox {
  display: flex;
  justify-content: center;
  padding-top: 3rem;
  padding-bottom: 3rem;
}

.skeleton-button-wrapper {
  margin-top: 3.188rem;
  display: flex;
  justify-content: start;
}

@media (max-width: 375px) {
  #mp__skeleton-register .skeleton-checkBox {
    width: 1.38rem;
  }

}

@media (max-width: 425px) {
  #mp__skeleton-register .mp_skeleton skeleton-checkBox-text {
    width: 100%;
  }
}

@media (max-width: 768px) {
  #mp__skeleton-register .mp__register-form {
    display: block;
  }

  #mp__skeleton-register .mp__skeleton-input-medium {
    width: 100%;
  }

  #mp__skeleton-register .skeleton-role-password-needs-3 {
    width: 100%;
  }

  #mp__skeleton-register .mp_skeleton skeleton-checkBox-text {
    width: 100%;
  }

  #mp__skeleton-register .mp__skeleton-input {
    width: 100%;
    height: 3rem;
    border-radius: .5rem;
  }

  .skeleton-checkBox {
    width: 1.2rem;
    height: 1rem;
  }

  .mp__skeleton-button {
    width: 100%;
  }
}
