#mp__product-manual-registration .cadastro-manual-produtos .tooltip__icon {
  cursor: pointer;
  width: 20px;
  height: 20px;
}

#mp__product-manual-registration .cadastro-manual-produtos-body {
  font-size: 14px;
}

#mp__product-manual-registration .cadastro-manual-produtos-body strong {
  color: #202121;
}

#mp__product-manual-registration .cadastro-manual-produtos .tooltip__content {
  width: 20px;
  height: 20px;
  padding-top: 3px;
}

#mp__product-manual-registration .cadastro-manual-produtos .panel-button {
  color: white;
  background-color: #5D4EE8;
}

#mp__product-manual-registration .cadastro-manual-produtos .panel-title {
  color: #202121;
}

#tooltip-top {
  width: 235px;
  padding: 0;
}

#tooltip-top .tooltip-inner a {
  color: #5D4EE8;
  font-weight: bold;
  text-decoration: none;
}

@media (min-width: 769px) and (max-width: 1439px) {
  #mp__product-manual-registration .cadastro-manual-produtos {
    margin-bottom: 352px;
  }

  #mp__product-manual-registration .cadastro-manual-produtos .panel-wrapper {
    width: 711px;
    height: 324px;
  }

  #mp__product-manual-registration .cadastro-manual-produtos .position-content {
    padding: 64px 70px 120px 242px;
  }

  #mp__product-manual-registration .cadastro-manual-produtos .panel-title {
    width: 60%;
  }

  #mp__product-manual-registration .cadastro-manual-produtos .cadastro-manual-produtos-body {
    width: 85%;
  }

  #mp__product-manual-registration .cadastro-manual-produtos .panel-button {
    width: 226px;
    margin-top: 25px;
  }

}

@media (min-width: 1440px) {
  #mp__product-manual-registration .cadastro-manual-produtos .panel-wrapper {
    width: 711px;
    height: 324px;
  }

  #mp__product-manual-registration .cadastro-manual-produtos .panel-title {
    width: 50%;
  }

  #mp__product-manual-registration .cadastro-manual-produtos .position-content {
    padding: 64px 0px 120px 242px;
  }

  #mp__product-manual-registration .cadastro-manual-produtos .cadastro-manual-produtos-body {
    width: 72%;
  }

  #mp__product-manual-registration .cadastro-manual-produtos .panel-button {
    width: 226px;
    margin-top: 25px;
  }
}

@media (max-width: 768px) {

  #mp__product-manual-registration .cadastro-manual-produtos .panel-wrapper {
    width: 90%;
  }

  #mp__product-manual-registration .cadastro-manual-produtos .panel-title {
    width: 70%;
  }

  #mp__product-manual-registration .cadastro-manual-produtos .cadastro-manual-produtos-body {
    width: 90%;
  }

  #mp__product-manual-registration .cadastro-manual-produtos .panel-button {
    width: 100%;
    text-transform: lowercase;
    margin-top: 35px;
  }

  #mp__product-manual-registration .cadastro-manual-produtos .panel-button {
    display: inline;
    color: white;
    background-color: #5D4EE8;
  }

  #mp__product-manual-registration .cadastro-manual-produtos .panel-button::before {
    content: "Ok, ";
    text-transform: capitalize;
  }

  #mp__product-manual-registration .cadastro-manual-produtos .panel-button::after {
    content: "!";
  }
}
