:root {
  --custom-blue: #5d4ee8;
  --custom-darker-blue: #17117a;
  --custom-green: #00a5a5;
  --custom-darker-green: #124f63;
}

#mp__button button.button {
  padding: 14px;
  font-weight: 600;
  font-size: 14px;
  display: block;
  box-shadow: initial;
  border-radius: 8px;
}

#mp__button button.button,
#mp__button button.button:focus,
#mp__button button.button:active:focus {
  box-shadow: initial;
  border-color: initial;
}

#mp__button button.bg-blue,
#mp__button button.bg-blue:active:focus,
#mp__button button.bg-blue:focus {
  background-color: var(--custom-blue);
}

#mp__button button.bg-blue:hover,
#mp__button button.outline-blue:hover,
#mp__button button.outline-blue:focus:hover {
  background-color: var(--custom-darker-blue);
  border-color: var(--custom-darker-blue);
  color: #FCFDFC;
}

#mp__button button.outline-blue,
#mp__button button.outline-blue:active:focus,
#mp__button button.outline-blue:focus {
  color: var(--custom-blue);
  background: #FCFDFC;
  border-color: var(--custom-blue);
}
form button.outline-blue,
form button.outline-blue:active:focus,
form button.outline-blue:focus {
  background: #F9F9F9;
}

#mp__button button.bg-green,
#mp__button button.bg-green:active:focus,
#mp__button button.bg-green:focus {
  background-color: var(--custom-green);
  box-shadow: initial;
  border-color: var(--custom-green);
}

#mp__button button.outline-green,
#mp__button button.outline-green:active:focus,
#mp__button button.outline-green:focus {
  color: var(--custom-green);
  background: #FCFDFC;
  border-color: var(--custom-green);
}

#mp__button button.outline-green:hover,
#mp__button button.bg-green:active,
#mp__button button.bg-green:hover {
  background-color: var(--custom-darker-green);
  border-color: var(--custom-darker-green);
  color: #FCFDFC;
}
