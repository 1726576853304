body {
  margin: 0;
  font-family: 'Poppins', sans-serif !important;
  font-style: normal;
  font-weight: 100;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100vh;
}

code {
  font-family: 'Poppins', sans-serif;
}
