.progress-strength {
  flex-grow: 1;
  background: #dbdcdc;
}

.progress-strength,
.progress-strength::before {
  height: 4px;
  border-radius: 2px;
}

.progress-strength::before {
  position: absolute;
  width: 50%;
  background: #ffdc42;
  content: '';
}

.strong-password::before {
  width: 100%;
  background: #699f10;
}

.strength-password {
  color: #6D6E70;
}
