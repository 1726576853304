@tailwind base;
@tailwind components;
@tailwind utilities;

.mp__select2-infos {
  display: flex;
  align-items: center;
  justify-content: space-between;

  font-size: 0.75rem;

  padding: 0 1rem 0.5rem;
}

.mp__select2-infos label {
  color: '#6D6E70';
}

.mp__select2-infos a {
  color: #5D4EE8;
  font-weight: 400;
}