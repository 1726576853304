#mp__panel.loading-default-panel {
  margin-bottom: 352px;
}

#mp__panel.loading-default-panel
.panel-content {
  width: 93%;
}

#mp__panel.loading-default-panel
.painel-content {
  margin-bottom: 24px;
}

@media (max-width: 768px) {
  #mp__panel.loading-default-panel
  .painel-content {
    padding: 0;
    margin: 0 15px 48px;

    display: flex;
    justify-content: center;
  }

  #mp__panel.loading-default-panel
  .image-mobile-wrapper {
    display: none;
  }

  #mp__panel.loading-default-panel
  .panel-content {
    text-align: center;
  }

  #mp__panel.loading-default-panel
  .loader-wrapper {
    display: flex;
    justify-content: center;
  }

  #mp__panel.loading-default-panel {
    margin: 48px 15px 216px 15px;
  }

  #mp__panel.loading-default-panel
  .panel-content {
    width: 100%;
  }

  #mp__panel.loading-default-panel
  .panel-description {
    margin: 0 auto;
    margin-bottom: 0;
    width: 90%;
  }
  #mp__panel.loading-default-panel
  .position-content {
    text-align: center;
  }
}

@media (min-width: 769px) and (max-width: 1439px) {
  #mp__panel.loading-default-panel
  .panel-wrapper {
    width: 70%;
    max-width: 770px;
    transform: translateX(40px);
  }

  #mp__panel.loading-default-panel
  .position-content {
    padding: 64px 120px 144px 240px;
  }
  #mp__panel.loading-default-panel
  .image-wrapper {
    left: -120px;
  }
}

@media (min-width: 1440px) {
  #mp__panel.loading-default-panel
  .panel-wrapper {
    width: 47.4%;
  }
  
  #mp__panel.loading-default-panel
  .position-content {
    padding: 64px 115px 120px 242px;
  }
}
