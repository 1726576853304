.hint .MuiSvgIcon-root {
  width: 14px;
  height: auto;
  margin-right: 5px;
  transform: translateY(3px);
}

.error-password-hint {
  color: #ff502d;
}

.check-password-hint {
  color: #5d4ee8;
}

.text-roles {
  color: #6D6E70
}
