@tailwind components;

@layer components {
    .mp__step-container {
        @apply container relative m-auto pt-6 max-w-3xl w-full;
    }
    .mp__step-wrapper {
        @apply flex justify-between items-center relative max-w-full;
    }
    .mp__step-wrapper::before {
        @apply absolute bg-neutralLight;

        content: "";
        width: 82%;
    }
    .mp__step-progress {
        @apply bg-primaryDark absolute;

        width: 0;
    }
    .mp__step-wrapper::before, .mp__step-progress {
        top: 23%;
        left: 9%;
        transform: translateY(-50%);
        height: 2px;
        z-index: -1;
        transition: 0.4s ease;
    }
    .mp__step-space {
        @apply grid justify-center justify-items-center px-2;
    }
    .mp__step-title {
        @apply text-xs;
    }
    .mp__step-icon, .mp__step-no_icon, .circle {
        @apply rounded-full border-2 p-1 mb-4 flex items-center justify-center;

        transition: 0.4s ease;
    }
    .mp__step-icon:after {
        @apply bg-primaryDark;
    }
    .mp__step-no_icon:after {
        @apply bg-neutralLight;
    }
    .mp__step-icon.active {
        @apply h-7 w-7 flex justify-center items-center;
    }
    .mp__step-icon.active svg {
        font-size: 0.85rem;
    }
    .mp__step-icon {
        @apply border-primaryDark bg-neutralLightest;
    }
    .mp__step-no_icon {
        @apply border-neutralLight bg-neutralLightest;
    }

    .mp__step-wrapper
    .mp__step-space {
        width: 120px;
    }
}