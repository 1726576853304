.loader-animation-svg {
  transform: rotate(180deg);
  height: auto;
}

.green-loader-bar,
.purple-loader-bar,
.blue-loader-bar {
  animation-duration: 1.4s;
  animation-timing-function: ease;
  animation-iteration-count: infinite;
}

.green-loader-bar {
  animation-name: green-bar;
}

.purple-loader-bar {
  animation-name: purple-bar;
  animation-delay: 0.3s;
}

.blue-loader-bar {
  animation-name: blue-bar;
  animation-delay: 0.6s;
}

@keyframes green-bar {
  50% {
    height: 70%;
  }
}

@keyframes purple-bar {
  40% {
    height: 85%;
  }
}

@keyframes blue-bar {
  30% {
    height: 100%;
  }
  90% {
    height: 33%;
  }
}
