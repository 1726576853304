@tailwind components;

@layer components {
  #mp__register .mp__register-header {
    @apply flex justify-center items-center text-center flex-col mt-6 sm:mt-12 px-5;
  }
  #mp__register .mp__register-header_title {
    @apply font-semibold mb-2;

    font-size: 1.75rem;
    line-height: 3rem;
  }
  #mp__register .mp__register-header_text {
    @apply mb-10 text-sm;
  }
  #mp__register .mp__register-form {
    @apply grid justify-center items-center max-w-2xl px-5 sm:px-24 mb-44 m-auto;
  }
  #mp__register .mp__register-form_content {
    @apply relative py-2 max-w-screen-sm mx-auto;
  }
  #mp__register .mp__register-form_content .tooltip__content {
    @apply absolute;

    left: 94%;
    bottom: 25%;
  }
  @media (max-width: 425px) {
    #mp__register .mp__register-form_content .tooltip__content {
      left: 91%;
    }
  }
  @media (max-width: 320px) {
    #mp__register .mp__register-form_content .tooltip__content {
      left: 88%;
    }
  }
  #mp__register .mp__register-form_checkbox {
    @apply flex flex-col justify-center py-12 max-w-screen-sm mx-auto;
  }
  #mp__register .mp__register-form_action {
    @apply flex justify-start max-w-screen-sm mx-auto;
  }
  #mp__register #mp__button {
    @apply w-full;
  }
  #mp__register #mp__button button.mp__register-form_action-button {
    @apply rounded-lg w-full;
  }
}

.mp__register_notify-container {
  position: fixed;
  top: 3.688rem;
  right: 5.5rem;
  width: 23.625rem;
}

@media (max-width: 300px) {
  #mp__register .mp__register-form_checkbox {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }

  #mp__register .mp__register-form {
    margin-bottom: 3rem;
  }
}

@media (max-width: 768px) {
  .mp__register_notify-container {
    position: fixed;
    top: 4.688rem;
    width: 100%;
    right: 0;
    padding: 24px 20px;
  }
}

#mp__input-register_email.is-invalid ~ .mp__input_register-email_tooltip {
  display: none;
}

.mp__register_notify-container button {
  background: transparent
    url('data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23000%27%3e%3cpath d=%27M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z%27/%3e%3c/svg%3e')
    center/1em auto no-repeat;
}
