#mp__panel.mp__integration-error-panel
.position-content {
  padding: 8% 17% 24% 35%;
}

#mp__integration-error_btn {
  margin-top: 24px;
}

@media (max-width: 768px) {
  #mp__panel.mp__integration-error-panel
  .position-content {
    padding: 0;
  }

  #mp__panel.mp__integration-error-panel .panel-title {
    width: 85%;
    margin: 25px auto 24px;
  }

  #mp__button
  #mp__integration-error_btn {
      display: initial;
      margin: 3rem 0 7rem ;
  }
}

@media (max-width: 1024px) {
  #mp__panel.mp__integration-error-panel 
  .image-wrapper {
    left: -18%;
  }
}

