:root {
  --custom-default-neutral: #202121;
  --custom-success-lightest: #f5fdd2;
  --custom-success-medium: #a2dd21;
  --custom-success-darkest: #3d6a06;
}

.mp__contract-progressbar {
  margin-bottom: 48px;
}

.mp__contract-text-wrapper {
  text-align: center;

  margin-bottom: 40px;
}

.mp__contract-title {
  font-size: 14px;
  line-height: 24px;

  color: var(--custom-default-neutral);
  font-weight: 600;
}

.mp__contract-subtitle {
  font-size: 14px;
  line-height: 24px;

  color: var(--custom-default-neutral);
  font-weight: 400;
}

.mp__contract-button-wrapper .button {
  width: 470px;
}

.mp__error-notify {
  background-color: #ffe8d5;
  color: #ff502d;
  border: 1px solid;
}

.mp__contract-button-wrapper #mp__button {
  display: flex;
  justify-content: center;
}

.mp__contract {
  display: grid;
  justify-content: center;
  align-items: center;

  max-width: 44.4rem;
  margin: auto;
  margin-bottom: 11.75rem;
}
.mp__contract .wrapper-document {
  margin-bottom: 3rem;
  border: 1px solid rgb(219 220 220 / 30%);
  border-radius: 8px;
}

.mp__contract-progress_mobile {
  display: none;
  margin-bottom: 48px;
}

.mp__contract-text-mobile-wrapper {
  display: none;
}

@media (min-width: 320px) and (max-width: 359px) {
  .mp__contract {
    margin-bottom: 0;
  }

  .mp__contract .contract {
    overflow: scroll;
    width: 310px;
  }

  .mp__contract .react-pdf__Page {
    width: 100% !important;
  }

  .mp__contract-progress_mobile {
    display: block;
    margin-bottom: 48px;
  }

  .mp__contract-button-wrapper .button {
    width: 100%;
  }

  .mp__contract-text-wrapper {
    display: none;
  }

  .mp__contract-text-mobile-wrapper {
    display: block;
    padding-bottom: 1.5rem;
    width: 95%;
  }

  .mp__contract-content {
    padding: 24px 20px 132px;
  }

  .mp__contract-button-wrapper #mp__validate-contract-custom-button {
    margin: 0 20px;
  }

  .mp__contract-progressbar {
    display: none;
  }
}

@media (min-width: 570px) {
  .mp__contract .contract {
    width: 100% !important;
  }
}

@media (min-width: 360px) and (max-width: 768px) {
  .mp__contract {
    max-width: 100%;
    margin-bottom: 0;
  }

  .mp__contract .react-pdf__Page {
    width: 100% !important;
  }
  .mp__contract-progressbar {
    display: none;
  }

  .mp__contract .contract {
    width: 360px;
    overflow: scroll;
  }

  .mp__contract-progress_mobile {
    display: block;
  }

  .mp__contract-button-wrapper .button {
    width: 100%;
  }
  .mp__contract-text-wrapper {
    display: none;
  }

  .mp__contract-text-mobile-wrapper {
    display: block;
    width: 80%;
    margin-bottom: 24px;
  }

  .mp__contract-content {
    padding: 24px 20px 132px;
  }

  .mp__contract-button-wrapper #mp__validate-contract-custom-button {
    margin: 0 20px;
  }
}

.mp__validated-contract-notify {
  background-color: var(--custom-success-lightest);
  color: var(--custom-success-darkest);
  border-color: var(--custom-success-medium);
}

.mp__validated-contract-notify-container {
  position: fixed;
  top: 3.688rem;
  right: 5.5rem;
  width: 23.625rem;
}

@media (max-width: 768px) {
  .mp__validated-contract-notify-container {
    top: 4.688rem;
    width: 100%;
    right: 0;
    padding: 24px 20px;
  }
}

.mp__acceptance-checkbox-wrapper {
  display: flex;
  justify-content: center;

  margin-bottom: 27px;
}

.mp__acceptance-checkbox-wrapper input:disabled ~ span {
  opacity: .65;
}

.mp__validated-contract-notify-container .btn-close {
  background-image: url('../../assets/svg/closeNotification.svg');
  opacity: 1;
  filter: invert(48%) sepia(79%) saturate(2476%) hue-rotate(41deg) brightness(118%) contrast(119%);
}
