.custom-ordered-list {
  counter-reset: list-counter;
  font-size: 12px;
}

.custom-ordered-item {
  counter-increment: list-counter;
  display: flex;
  align-items: center;
  gap: 16px;
  margin-bottom: 18px;
}
.custom-ordered-item::before {
  content: counter(list-counter);
  height: 50px;
  font-size: 28px;
  border: 1px solid #5D4EE8;
  color: #5D4EE8;
  background: #fff;
  display: flex;
  border-radius: 8px;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  flex-basis: 50px;
  font-weight: bolder;
}
