:root {
  --custom-primary-dark: #5D4EE8;
  --custom-primary-darkest: #17117A;
  --custom-error-lightest: #FFE8D5;
  --custom-error-medium: #FF502D;
  --custom-neutral-dark: #6D6E70;
  --custom-neutral-lightest: #f9f9f9;
  --custom-neutral-light: #dbdcdc;
  --custom-neutral-white: #fcfdfc;
  --custom-default-neutral: #202121;
}

#mp__panel.panel-container {
  display: flex;
  align-items: center;
  justify-content: center;
  
  margin: 48px 0 120px 0;
}

@media (max-width: 768px) {
  #mp__panel.panel-container {
    margin: 23px 15px 120px 15px;
  }
}

#mp__panel .panel-wrapper   {
  position: relative;
  width: 100%;
  max-width: 712px;
  background-color: rgb(219 220 220 / 20%);
  border-radius: 8px;
  min-height: 372px;
}

@media (max-width: 768px) {
  #mp__panel .panel-wrapper  {
    background: none;
  }
}

#mp__panel .position-content {
  padding: 64px 20% 12% 35%;
}

@media (max-width: 768px) {
  #mp__panel .position-content {
    padding: 0;
  }
}

#mp__panel .panel-title {
  color: var(--custom-default-neutral);
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
  margin-bottom: 24px;
  font-family: 'Poppins';
}

#mp__panel .panel-title,
#mp__panel .tooltip__content {
  display: inline-block;
}

@media (max-width: 768px) {
  #mp__panel .panel-title {
    width: 98%;
  }
}

#mp__panel .panel-description {
  color: var(--custom-default-neutral);
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  margin-bottom: 24px;
  font-family: 'Poppins';
}
@media (max-width: 768px) {
  #mp__panel .panel-description {
    width: 100%;
  }
}

#mp__panel .panel-button {
  width: 100%;
  background: var(--custom-primary-dark);
  border: var(--custom-primary-dark);
  border-radius: 8px;
  color: var(--custom-neutral-white);
  font-family: 'Poppins';
}
@media (min-width: 768px) {
  #mp__panel .panel-button {
    max-width: 226px;
  }
}

#mp__panel .panel-button:focus,
#mp__panel .panel-button:hover {
  background: var(--custom-primary-darkest);
  border: var(--custom-primary-darkest);
}

#mp__panel .panel-link {
  color: var(--custom-primary-dark);
  font-size: 14px;
  font-weight: 600;
  line-height: 24px;
  font-family: 'Poppins';
}

#mp__panel .link-container {
  margin-top: 24px;
}

#mp__panel .image-wrapper {
  position: absolute;
  top: 24px;
  left: -18%;
}

@media (max-width: 1024px) {
  #mp__panel .image-wrapper {
    left: -38%;
  }
}

@media (max-width: 768px) {
  #mp__panel .image-wrapper  {
    display: none;
  }
  #mp__panel .image-mobile-wrapper {
    margin: 0 25px;
  }
  
  #mp__panel .panel-wrapper {
    width: 100%;
  }
}

@media (min-width: 769px) {
  #mp__panel .image-mobile-wrapper  {
    display: none;
  }
}

#mp__panel .loader-wrapper {
  margin-bottom: 24px;
}
